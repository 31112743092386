import Image from "next/image";
import FaqCollabHub from "./CollabFaQs";
import VideoBlock from "../dao/VideoBlock";
import React, { useState } from "react";

const CollabHub = () => {
  const [videoModal, setvideoModal] = useState(false);
  return (
    <section className="bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <div className="mx-auto mb-12 max-w-xl text-center">
          <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
            IMPACT NETWORK
          </h2>

          <p className="text-lg dark:text-jacarta-300 text-center">
            Impactoverse is pleased to partner with
          </p>
          <div className="flex items-center justify-center">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 42.3"
              xmlSpace="preserve"
              className="mr-2 h-8 w-320 flex-shrink-0 fill-accent "
            >
              <g>
                <path
                  d="M74.6,6.9c5.6,0,9.4,3.8,9.4,9h-5.2c0-2.5-1.6-4-4.3-4c-2.7,0-4.3,1.6-4.3,3.1c0,5,14.6,2.3,14.6,12.6
		c0,4-4,8.1-10.3,8.1c-6.3,0-10.1-3.9-10.1-9.3h5.4c0,2.5,1.8,4.3,4.7,4.3c3.1,0,4.7-1.6,4.7-3.4c0-5.2-14.6-2.5-14.6-12.3
		C64.7,10.7,68.5,6.9,74.6,6.9z"
                />
                <path
                  d="M88.8,26.9V14.8h5.2v11.5c0,2.7,1.8,4.5,4.3,4.5c2.5,0,4.3-1.8,4.3-4.5V14.8h5.2v20.7h-3.8l-0.9-2
		c0,0-2.5,2.3-5.9,2.3C92.1,35.7,88.8,32.3,88.8,26.9z"
                />
                <path
                  d="M116.4,28.9c0.2,1.3,1.3,2.5,3.1,2.5c1.8,0,2.9-1.1,2.9-2.3c0-2.9-10.6-1.1-10.6-8.3c0-3.4,2.9-6.3,7.4-6.3
		c5,0,7.6,2.7,8.1,6.3h-5c-0.2-0.9-1.3-2-2.9-2c-1.6,0-2.5,0.9-2.5,2c0,2.9,10.6,1.1,10.6,8.6c0,3.4-2.9,6.3-7.6,6.3
		c-5.4,0-8.1-2.7-8.6-6.7H116.4z"
                />
                <path
                  d="M133.7,9.8h4.5v5h4.5v4.7h-4.5V28c0,1.8,1.1,2.9,2.9,2.9c0.4,0,0.9-0.1,1.3-0.2v4.5c-0.8,0.3-1.6,0.4-2.5,0.4
		c-4,0-7-2.9-7-7.4v-8.7h-3.1v-4.3h1.3c1.6,0,2.5-0.9,2.5-2.5L133.7,9.8z"
                />
                <path
                  d="M145.4,25.1c0-6.1,4.5-10.6,9.6-10.6c2.2,0,4.2,0.8,5.9,2.3l0.9-2h3.8v20.7h-3.8l-0.9-2
		c-1.6,1.4-3.7,2.2-5.9,2.3C149.9,35.7,145.4,31.2,145.4,25.1z M155.7,19.5c-2.9,0-5.2,2.3-5.2,5.6s2.3,5.6,5.2,5.6
		c2.9,0,5.2-2.3,5.2-5.6S158.7,19.5,155.7,19.5z"
                />
                <path
                  d="M170.3,9.2c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9S170.3,10.8,170.3,9.2
		C170.3,9.2,170.3,9.2,170.3,9.2z M175.7,35.4h-5.2V14.8h5.2V35.4z"
                />
                <path
                  d="M199.6,23.3v12.1h-5.2V24c0-2.7-1.8-4.5-4.3-4.5s-4.3,1.8-4.3,4.5v11.5h-5.2V14.8h3.8l0.9,2
		c0,0,2.5-2.3,5.9-2.3C196.2,14.5,199.6,17.9,199.6,23.3z"
                />
                <path
                  d="M216.9,30.5c3.2,0.1,6.1-1.9,7.2-5h5.6c-1.4,5.4-6.1,10.1-12.8,10.1c-7.4,0-13.7-6.3-13.7-14.4
		s6.3-14.4,13.7-14.4c6.5,0,11.2,4.7,12.6,10.1h-5.6c-0.7-2.5-3.1-5-7-5c-4.5,0-8.3,3.8-8.3,9.2S212.4,30.5,216.9,30.5z"
                />
                <path
                  d="M252.2,23.3v12.1H247V24c0-2.7-1.8-4.5-4.3-4.5c-2.5,0-4.3,1.8-4.3,4.5v11.5h-5.2V7.1h5.2v9.7
		c0,0,1.8-2.3,5.9-2.3C248.8,14.5,252.2,17.9,252.2,23.3z"
                />
                <path
                  d="M255.8,25.1c0-6.1,4.5-10.6,9.6-10.6c2.2,0,4.2,0.8,5.9,2.3l0.9-2h3.8v20.7h-3.8l-0.9-2
		c-1.6,1.4-3.7,2.2-5.9,2.3C260.3,35.7,255.8,31.2,255.8,25.1z M266.1,19.5c-2.9,0-5.2,2.3-5.2,5.6s2.3,5.6,5.2,5.6
		c2.9,0,5.2-2.3,5.2-5.6S269,19.5,266.1,19.5z"
                />
                <path
                  d="M280.7,9.2c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9
		C282.1,12.1,280.7,10.8,280.7,9.2C280.7,9.2,280.7,9.2,280.7,9.2z M286.1,35.4H281V14.8h5.2V35.4z"
                />
                <path
                  d="M309.9,23.3v12.1h-5.2V24c0-2.7-1.8-4.5-4.3-4.5c-2.5,0-4.3,1.8-4.3,4.5v11.5H291V14.8h3.8l0.9,2
		c0,0,2.5-2.3,5.9-2.3C306.5,14.5,309.9,17.9,309.9,23.3z"
                />
                <path d="M309,9.3h4.3v1.3h-1.4v3.8h-1.4v-3.8h-1.4L309,9.3z" />
                <path d="M317.2,14.4h-0.5l-1.3-2.6v2.6H314V9.3h1.5l1.4,2.9l1.4-2.9h1.5v5.2h-1.4v-2.6L317.2,14.4z" />
                <path d="M11.7,6.8c3-0.7,6.2-0.4,9,0.8l-2.1,5.1c-1.8-0.8-3.8-0.9-5.7-0.5L11.7,6.8z" />
                <path d="M3.9,11.4C6,9.1,8.7,7.5,11.7,6.8l1.2,5.3c-1.9,0.4-3.6,1.4-4.9,2.9L3.9,11.4z" />
                <path d="M0.3,19.7c0.3-3.1,1.6-6,3.6-8.3L8,15.1c-1.3,1.4-2.1,3.3-2.3,5.2L0.3,19.7z" />
                <path d="M2.2,28.6c-1.5-2.7-2.2-5.8-1.9-8.9l5.5,0.5c-0.2,2,0.2,3.9,1.2,5.6L2.2,28.6z" />
                <path d="M8.9,34.7c-2.8-1.3-5.2-3.4-6.7-6.1l4.8-2.7c1,1.7,2.4,3,4.2,3.8L8.9,34.7z" />
                <path d="M17.9,35.7c-3,0.6-6.2,0.2-9-1l2.3-5c1.8,0.8,3.8,1,5.6,0.7L17.9,35.7z" />
                <path
                  d="M25.8,31.3c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.6,0.5-0.9,0.8c-1.8,1.4-3.9,2.4-6.2,2.8l-1.1-5.4
		c1-0.2,1.8-0.5,2.7-1c0.4-0.2,0.8-0.5,1.2-0.8c0.2-0.1,0.4-0.3,0.5-0.5s0.3-0.3,0.5-0.5L25.8,31.3z"
                />
                <path
                  d="M29.4,23.2c-0.2,1.4-0.6,2.8-1.1,4.1c-0.3,0.7-0.6,1.4-1,2.1c-0.2,0.3-0.4,0.7-0.7,1c-0.2,0.3-0.5,0.7-0.8,0.9
		l-4-3.7c0.2-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.4,0.4-0.8,0.6-1.3c0.4-1,0.7-2.1,0.8-3.1L29.4,23.2z"
                />
                <path
                  d="M31.1,16.8c-0.5,0.9-0.9,1.8-1.1,2.8c-0.1,0.2-0.1,0.5-0.2,0.8l-0.1,0.4l-0.1,0.5c-0.1,0.6-0.2,1.3-0.3,1.9
		l-5.4-1c0.1-0.6,0.2-1.2,0.3-1.8l0.1-0.4l0.1-0.5c0.1-0.4,0.1-0.7,0.2-1.1c0.3-1.4,0.9-2.8,1.6-4.1L31.1,16.8z"
                />
                <path
                  d="M35.2,12.8c-0.9,0.4-1.6,1-2.3,1.6c-0.7,0.7-1.3,1.5-1.7,2.4l-4.8-2.6c0.7-1.3,1.6-2.5,2.7-3.6
		c1.1-1.1,2.4-2,3.8-2.7L35.2,12.8z"
                />
                <path
                  d="M40.8,12.1c-0.5-0.1-1-0.1-1.4-0.2c-0.2,0-0.5,0-0.7,0s-0.5,0-0.7,0.1c-1,0.1-1.8,0.4-2.7,0.8l-2.4-4.9
		c1.4-0.7,2.9-1.1,4.4-1.3c0.4-0.1,0.8-0.1,1.1-0.1c0.4,0,0.8,0,1.1,0c0.8,0,1.5,0.1,2.3,0.2L40.8,12.1z"
                />
                <path d="M45.8,14.8c-1.3-1.4-3.1-2.4-5-2.7l1-5.4c3,0.6,5.8,2.1,7.9,4.3L45.8,14.8z" />
                <path d="M48.3,20c-0.3-1.9-1.1-3.7-2.5-5.1l4-3.8c2.1,2.3,3.5,5.1,3.9,8.2L48.3,20z" />
                <path d="M47.3,25.6c0.9-1.7,1.3-3.7,1-5.6l5.4-0.7c0.4,3.1-0.1,6.2-1.6,8.9L47.3,25.6z" />
                <path d="M43.2,29.6c1.7-0.9,3.2-2.3,4.1-4l4.8,2.6c-1.4,2.7-3.7,4.9-6.5,6.3L43.2,29.6z" />
                <path d="M37.5,30.4c1.9,0.3,3.9,0,5.6-0.9l2.4,4.9c-2.8,1.4-5.9,1.8-9,1.3L37.5,30.4z" />
                <path d="M32.5,27.8c1.4,1.4,3.1,2.3,5.1,2.6l-0.9,5.4c-3-0.5-5.9-2-8-4.1L32.5,27.8z" />
              </g>
            </svg>
          </div>
        </div>
        <div className="lg:flex lg:flex-nowrap">
          <div className="lg:w-[59%]">
            <figure className="relative mb-8 overflow-hidden rounded-3xl before:absolute before:inset-0 before:bg-jacarta-900/25">
              <button
                onClick={() => setvideoModal(true)}
                className="js-video-modal-trigger absolute top-1/2 left-1/2 flex h-16 w-16 -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full border-2 border-white transition-transform will-change-transform hover:scale-90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-8 w-8 fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                </svg>
              </button>
              <Image
                width={690}
                height={540}
                src="/images/dao/video_cover_dao.jpg"
                className="w-full h-full object-cover"
                alt="video"
              />
            </figure>
            {/* <!-- end  video main block --> */}

            {/* <!-- YouTube Video Modal --> */}
            <div
              className={
                videoModal
                  ? "modal lightbox fade show block"
                  : "modal lightbox fade"
              }
            >
              <div className="modal-dialog modal-dialog-centered modal-xl w-full">
                <div className="modal-content border-0 bg-transparent">
                  <div className="modal-body p-0 relative">
                    <button
                      onClick={() => setvideoModal(false)}
                      type="button"
                      className="btn-close position-absolute top-0 end-0 p-3 z-10"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="#fff"
                        className="h-6 w-6"
                      >
                        <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                      </svg>
                    </button>
                    <div
                      id="lightboxCarousel-d7ewe4ig"
                      className="lightbox-carousel carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="position-absolute top-50 start-50 translate-middle text-white">
                            <div className="spinner-border" role="status"></div>
                          </div>
                          <div className="ratio ratio-16x9">
                            <iframe
                              src="https://www.youtube.com/embed/dV_d0tTSw4U"
                              title="YouTube video player"
                              allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[41%] lg:pl-24">
            <p className="mt-10 mb-5 dark:text-jacarta-300">
              SustainChain™ is the world’s largest sustainability action
              platform that helps humanity collectively achieve the SDGs. As a
              public service platform, it brings together impact investors,
              innovators, purpose-driven brands, NGOs and alliances with a
              shared mission of building a more sustainable supply chain.
            </p>
            <a
              href="#"
              className="mb-10 inline-block text-sm font-bold text-accent"
            >
              Learn More
            </a>
            <FaqCollabHub />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollabHub;
