import React from "react";
import Meta from "../../components/Meta";
import { Feature_collections } from "../../components/component";
import NeedHelpBlock from "../../components/crypto/nee-help-block";
import ProcessCta from "../../components/cryto-trading/ProcessCta";
import Benifits from "../../components/dao/Benifits";
import NewseLatter2 from "../../components/dao/newseLatter2";
import Hero_11 from "../../components/hero/hero_11";
import CollabHub from "../../components/home/collabHub";
import WhatWeDo from "../../components/home/WhatWeDo";
import HowItWorks from "../../components/home/HowItWorks";
import WeeklyDigest from "../../components/home/WeeklyDigest";

const Home_1 = () => {
  return (
    <main>
      <Meta title="Home" />
      <Hero_11 />
      <WhatWeDo />
      <HowItWorks />
      <Feature_collections />
      <NewseLatter2 bgWhite={false} />
      <Benifits />
      <CollabHub />
      <WeeklyDigest/>
      {/* <FancyBlock /> */}
         {/* <ProcessCta /> */}
    </main>
  );
};

export default Home_1;
