import BeniftContent from "../dao/BeniftContent";
import SingleProcessBlock from "./../../components/cryto-trading/SingleProcessBlock";

const HowItWorks = () => {
  return (
    <>
      {/* <!-- Process / CTA --> */}
      <section className="py-24 dark:bg-jacarta-800">
        <div className="container">
          <div className="mx-auto mb-12 text-center">
            <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
              HOW IT WORKS
            </h2>
            <p className="text-lg dark:text-jacarta-300">
              Our Impact DOT (digital ownership token) campaigns are a great way
              to collaborate to raise funds with mutual benefits. Join a
              cause-driven community, support campaigns you care about, align
              with purposeful brands and influencers, and build an impact
              portfolio that benefits you and the planet. Here’s an overview of
              how it works.
            </p>
          </div>
          {/* End mx-auto */}

          <BeniftContent />

          {/* <CallToActions /> */}
        </div>
      </section>
      {/* <!-- end process / cta -->  */}
    </>
  );
};

export default HowItWorks;
